import React, { useState, useRef, useEffect } from 'react';
import { ethers } from 'ethers';
import { HDNodeWallet } from "ethers/wallet";
import Modal from 'react-modal';
import './App.css';
import { privacyPolicyText, cookieText } from './constants/footer_constants';
import stake1 from './images/stake1.jpg'
import stake2 from './images/stake2.jpg'
import airdrop1 from './images/airdrop1.jpg'
import airdrop2 from './images/airdrop2.jpg'
import contractABI from './abi/PuppyShibaInu.json';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers'
import { useWeb3Modal } from 'web3modal';
import Timer from './Timer';
import WalletConnect from "@walletconnect/client";
import about1 from './images/about1.jpg'
import about2 from './images/about2.jpg'
import about3 from './images/about3.jpg'
import about4 from './images/about4.jpg'
import favicon from './images/favicon.png'
import navigator from './images/navigator.png'
import tokenomics from './images/tokenomics.png'

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import './i18n';

import flag_ar from './images/flags/arabic.png'
import flag_bg from './images/flags/bulgarian.png'
import flag_zh from './images/flags/chinese.png'
import flag_cz from './images/flags/czech.png'
import flag_nl from './images/flags/dutch.png'
import flag_en from './images/flags/english.png'
import flag_fr from './images/flags/french.png'
import flag_de from './images/flags/german.png'
import flag_el from './images/flags/greek.png'
import flag_hu from './images/flags/hungarian.png'
import flag_id from './images/flags/indonesian.png'
import flag_it from './images/flags/italian.png'
import flag_jp from './images/flags/japanese.png'
import flag_kr from './images/flags/korean.png'
import flag_pl from './images/flags/polish.png'
import flag_pt from './images/flags/portuguese.png'
import flag_ro from './images/flags/romanian.png'
import flag_ru from './images/flags/russian.png'
import flag_sk from './images/flags/slovak.png'
import flag_es from './images/flags/spanish.png'
import flag_tr from './images/flags/turkish.png'
import flag_vn from './images/flags/vietnamese.png'

function App() {
    let scrollToSection = (sectionId) => {
        let section = document.getElementById(sectionId);
        if (section) {
            let offset = 72;
            window.scrollTo({
                top: section.offsetTop - offset,
                behavior: 'smooth'
            });
        }
    };

    // Smart Contract Connection
    const provider = new ethers.JsonRpcProvider('https://mainnet.infura.io/v3/d1015e39c8c54704892ffed5a18791ca');
    // TODO: change address with release!
    const address = '0xe13078d83af38d3ba1a9e38a48eaddaa3fb202a8';
    const contractInstance = new ethers.Contract(address, contractABI, provider);

    // Token name
    let name = 'PuppyShibaInu'

    // Token symbol
    let symbol = 'PSHIB'

    // Token price
    let [tokenPrice, setTokenPrice] = useState("TokenPrice");
    useEffect(() => {
        contractInstance.tokenPrice().then(tokenPriceValue => {
            setTokenPrice(parseInt(tokenPriceValue)/10000);
        }).catch(error => {
            // console.error("Error fetching price:", error);
        });
    }, [contractInstance]);

    // Token decimals
    let tokenDecimals = 18

    // Next Raise
    let [nextRaise, setNextRaise] = useState(0);
    useEffect(() => {
        contractInstance.nextRaise().then(nextRaiseValue => {
            setNextRaise(parseInt(nextRaiseValue));
        }).catch(error => {
            // console.error("Error fetching nextRaise:", error);
        });
    }, [contractInstance]);

    // Ico Balance
    let [icoBalance, setIcoBalance] = useState(0);
    useEffect(() => {
        if (isConnected) {
            contractInstance.icoBalance(signerAddress).then(icoBalanceValue => {
                setIcoBalance(ethers.formatEther(icoBalanceValue));
            }).catch(error => {
                // console.error("Error fetching icoBalance:", error);
            });
        }
    }, [contractInstance]);

    // stake Balance
    let [stakeOf, setStakeOf] = useState(0);
    useEffect(() => {
        contractInstance.stakeOf(signerAddress).then(stakeOfValue => {
            setStakeOf(ethers.formatEther(stakeOfValue));
        }).catch(error => {
            // console.error("Error fetching stakeOf:", error);
        });
    }, [contractInstance]);

    let [stakeInput, setStakeInput] = useState(0);
    let handleStakeInput = (event) => {
        setStakeInput(event.target.value);
    }

    // stake tokens
    const [stakeStatus, setStakeStatus] = useState(null);
    let handleStakeButton = async () => {
        if (!isConnected) {
            try {
                await connectWallet();
            } catch(error) {
                setPurchaseStatus('connection_error');
                return
            }
        }

        if (stakeInput <= 0) {
            return
        }

        try {
            const stakeValueInWei = ethers.parseUnits(stakeInput, 'ether')
            // console.log('stakeValueInWei: ' + stakeValueInWei)

            // Aufrufen der stake Methode des Smart Contracts
            let transaction = await contractInstance.connect(signer).stakeDuringICO(stakeValueInWei);

            // Warten auf die Bestätigung der Transaktion
            await transaction.wait();

            // Aktualisieren der icoBalance
            const newIcoBalance = await contractInstance.stakeOf(signerAddress);
            setStakeOf(ethers.formatEther(newIcoBalance));

            setStakeStatus('success')
        } catch (error) {
            // console.error('Error staking coins:', error);
            setStakeStatus('error')
        }
    };

    // moneyEarned
    let [moneyEarned, setMoneyEarned] = useState(0);
    useEffect(() => {
        contractInstance.moneyEarned().then(moneyEarnedValue => {
            setMoneyEarned(parseInt(moneyEarnedValue)/10000);
        }).catch(error => {
            // console.error("Error fetching earnings:", error);
        });
    }, [contractInstance]);

    // Goals
    let [currentGoal, setCurrentGoal] = useState(1);
    let [maxAmountToRaise, setMaxAmountToRaise] = useState(1);
    useEffect(() => {
            switch (true) {
                case moneyEarned >= 7.5*10**6:
                    setMaxAmountToRaise(10*10**6);
                    break;
                case moneyEarned >= 5*10**6:
                    setMaxAmountToRaise(7.5*10**6);
                    break;
                case moneyEarned >= 2.5*10**6:
                    setMaxAmountToRaise(5*10**6);
                    break;
                case moneyEarned >= 1*10**6:
                    setMaxAmountToRaise(2.5*10**6);
                    break;
                case moneyEarned >= 0.5*10**6:
                    setMaxAmountToRaise(1*10**6);
                    break;
                default:
                    setMaxAmountToRaise(0.5*10**6);
            }
        }, [moneyEarned]);

    // FillBar Calculation
    let filledPercentage = (moneyEarned / maxAmountToRaise) * 100;
    useEffect(() => {
            // Berechne den prozentualen Anteil des gefüllten Balkens basierend auf dem eingenommenen Geld
            let filledPercentage = (moneyEarned / maxAmountToRaise) * 100;
            if (filledPercentage >= 100) {
                filledPercentage = 100;
            }
            // Aktualisiere die Breite des gefüllten Balkens entsprechend dem prozentualen Anteil
            let overallFillBar = document.getElementById('overall-fill-bar');
            if (overallFillBar) {
                overallFillBar.style.width = filledPercentage + '%';
            }
        }, [moneyEarned, maxAmountToRaise]);

    // Wallet Connect
    const projectId = '17cc97ef515f41b9ed89fa22b7f67008'
    const mainnet = {
      chainId: 1,
      name: 'Ethereum',
      currency: 'ETH',
      explorerUrl: 'https://etherscan.io',
      rpcUrl: 'https://cloudflare-eth.com'
    }
    const metadata = {
      name: 'PuppyShibaInu',
      description: 'Website to buy PSHIB during ICO',
      url: 'https://www.puppyshibainu.com',
      icons: ['https://www.puppyshibainu.com']
    }
    createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: [mainnet],
      projectId,
    })

    const { walletAddress, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const [signer, setSigner] = useState(null);
    const [signerAddress, setSignerAddress] = useState('');
    const [ethersProvider, setEthersProvider] = useState(null)

    // Tries to connect the wallet
        const connectWallet = async () => {
            try {
                const provider = new BrowserProvider(walletProvider);
                const temporarySigner = await provider.getSigner();
                setEthersProvider(provider);
                setSigner(temporarySigner);
                const address = await temporarySigner.getAddress();
                setSignerAddress(address);
            } catch (error) {
                // console.error('Error connecting wallet:', error);
                throw error;
            }
        };

        // useEffect hook to update values when wallet state changes
        useEffect(() => {
            if (isConnected) {
                connectWallet();
            }
        }, [isConnected, walletProvider]);

    // Input
    let [ethInputValue, setEthInputValue] = useState(0);
    let [paymentValue, setPaymentValue] = useState(0);
    let [receiveValue, setReceiveValue] = useState(0);
    let handlePaymentInput = (event) => {
        let value = event.target.value;
        setPaymentValue(value);

        setReceiveValue((((value * ethPrice) / tokenPrice).roundToMaxDecimals(8)).toString()); // Calculate Amount of Tokens for ETH
        setEthInputValue(value)
    };

    let handleReceiveInput = (event) => {
        let value = event.target.value;
        setReceiveValue(value);

        setPaymentValue(((value * tokenPrice) / ethPrice).roundToMaxDecimals(8)); // Calculate Amount of ETH for Tokens
        setEthInputValue(((value * tokenPrice) / ethPrice).roundToMaxDecimals(8));
    };

    Number.prototype.roundToMaxDecimals = function(maxDecimals) {
        let factor = Math.pow(10, maxDecimals);
        return Math.round(this * factor) / factor;
    };

    // round to decimals
    const roundToDecimals = (number, decimals) => {
        const multiplier = 10 ** decimals;
        const roundedNumber = Math.ceil(number * multiplier) / multiplier;
        return roundedNumber.toFixed(decimals);
    };

    // Buy Tokens
    const [purchaseStatus, setPurchaseStatus] = useState(null);
    let handleBuyTokens = async () => {
        if (!isConnected) {
            try {
                await connectWallet();
            } catch(error) {
                setPurchaseStatus('connection_error');
                return
            }
        }

        if (receiveValue <= 0) {
            return
        }

        try {
            const receiveValueInWei = ethers.parseUnits(receiveValue, 'ether');

            // Calculate gas cost
            let gasCost = (roundToDecimals(receiveValue * tokenPrice, 1)) / ethPrice;
            let gasCostInWei = ethers.parseUnits(roundToDecimals(gasCost, tokenDecimals).toString());

            // Gas-Costs
            let gasLimit = await contractInstance.buy.estimateGas(receiveValueInWei, { value: gasCostInWei.toString() });
            let gasPrice = (await provider.getFeeData()).gasPrice;
            gasCost = gasLimit * gasPrice;
            let value = ethers.parseUnits(paymentValue.toString(), 'ether') + gasCost

            // Aufrufen der buyTokensDuringICO Methode des Smart Contracts
            let transaction = await contractInstance.connect(signer).buy(receiveValueInWei, {value: value});

            // Warten auf die Bestätigung der Transaktion
            await transaction.wait();

            // Aktualisieren der icoBalance
            const newIcoBalance = await contractInstance.icoBalance(signerAddress);
            setIcoBalance(ethers.formatEther(newIcoBalance));

            setPurchaseStatus('success')
        } catch (error) {
            // console.error('Error buying coins:', error);
            setPurchaseStatus('error')
        }
    };

    // ETH Price
    let [ethPrice, setEthPrice] = useState(null);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const ethPriceValue = await contractInstance.ethPrice();
                const parsedEthPrice =  (parseFloat(ethPriceValue) / 10**18);
                setEthPrice(parsedEthPrice);

                if (ethInputValue > 0) {
                    const receiveValue = ((ethInputValue * parsedEthPrice) / tokenPrice).toFixed(8);
                    setReceiveValue(receiveValue);
                }
            } catch (error) {
                // console.error('Error fetching prices:', error);
            }
        };

        // Die Preise alle 60 Sekunden aktualisieren
        let intervalId = setInterval(fetchPrices, 60000);

        // Initialisierung der Preise beim ersten Rendern der Komponente
        fetchPrices();

        // Cleanup-Funktion für den Effekt
        return () => clearInterval(intervalId);
    }, []); // Leeres Abhängigkeitsarray, um sicherzustellen, dass der Effekt nur einmal ausgeführt wird

    // Topper
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const languageDropdownRef = useRef(null);

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
            setSelectedLanguage(storedLanguage);
            i18n.changeLanguage(storedLanguage);
        } else {
            const defaultLanguage = 'en';
            setSelectedLanguage(defaultLanguage);
            localStorage.setItem('selectedLanguage', defaultLanguage);
            i18n.changeLanguage(defaultLanguage);
        }
    }, []);

    useEffect(() => {
        // Überprüfe beim Laden der Seite die URL-Sprache
        const urlLanguage = window.location.pathname.substr(1); // Extrahiere den Sprachcode aus der URL
        if (urlLanguage && urlLanguage !== selectedLanguage) {
            changeLanguage(urlLanguage); // Ändere die Sprache basierend auf der URL
        }
    }, [selectedLanguage]);

    const changeLanguage = (code) => {
        setSelectedLanguage(code);
        i18n.changeLanguage(code);
        localStorage.setItem('selectedLanguage', code);
        setLanguageShowDropdown(false);
    };

    let [showLanguageDropdown, setLanguageShowDropdown] = useState(false);

    const handleLanguageToggleDropdown = () => {
        setLanguageShowDropdown(!showLanguageDropdown);
    };

    const handleClickOutside = (event, ref, setState) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setState(false);
        }
    };

    useEffect(() => {
        const handleClickOutsideLanguageDropdown = (event) => {
            handleClickOutside(event, languageDropdownRef, setLanguageShowDropdown);
        };
        document.addEventListener('click', handleClickOutsideLanguageDropdown, true);
        return () => {
            document.removeEventListener('click', handleClickOutsideLanguageDropdown, true);
        };
    }, []);

    let languages = [
        { name: 'Arabic', flag: flag_ar, code: 'ar' },
        { name: 'Bulgarian', flag: flag_bg, code: 'bg' },
        { name: 'Chinese (Simplified)', flag: flag_zh, code: 'zh_hans' },
        { name: 'Chinese (Traditional)', flag: flag_zh, code: 'zh_hant' },
        { name: 'Czech', flag: flag_cz, code: 'cz' },
        { name: 'Dutch', flag: flag_nl, code: 'nl' },
        { name: 'English', flag: flag_en, code: 'en' },
        { name: 'French', flag: flag_fr, code: 'fr' },
        { name: 'German', flag: flag_de, code: 'de' },
        { name: 'Greek', flag: flag_el, code: 'el' },
        { name: 'Hungarian', flag: flag_hu, code: 'hu' },
        { name: 'Indonesian', flag: flag_id, code: 'id' },
        { name: 'Italian', flag: flag_it, code: 'it' },
        { name: 'Japanese', flag: flag_jp, code: 'jp' },
        { name: 'Korean', flag: flag_kr, code: 'kr' },
        { name: 'Polish', flag: flag_pl, code: 'pl' },
        { name: 'Portuguese', flag: flag_pt, code: 'pt' },
        { name: 'Romanian', flag: flag_ro, code: 'ro' },
        { name: 'Russian', flag: flag_ru, code: 'ru' },
        { name: 'Slovak', flag: flag_sk, code: 'sk' },
        { name: 'Spanish', flag: flag_es, code: 'es' },
        { name: 'Turkish', flag: flag_tr, code: 'tr' },
        { name: 'Vietnamese', flag: flag_vn, code: 'vn' },
    ];

    // i18next
    const { t } = useTranslation();

    // Footer
    let [modalIsOpen, setModalIsOpen] = useState(false);
    let closeModal = () => setModalIsOpen(false);
    let [modalTitle, setModalTitle] = useState('');
    let [modalContent, setModalContent] = useState([]);
    let openModal = (title) => {
        setModalTitle(title);
        setModalContent(paragraphs[title] || []);
        setModalIsOpen(true); // Setzt modalIsOpen auf true, um das Modal zu öffnen
    };

    let paragraphs = {
        'Privacy Policy': privacyPolicyText.split('\n\n'),
        'Cookies': cookieText.split('\n\n')
    };


    // Navigator
    let [showNavigatorDropdown, setNavigatorShowDropdown] = useState(false);
    const navigatorDropdownRef = useRef(null); // Ref für das Navigator-Dropdown

    const handleNavigatorToggleDropdown = () => {
        setNavigatorShowDropdown(!showNavigatorDropdown);
    };

    useEffect(() => {
        const handleClickOutsideNavigatorDropdown = (event) => {
            handleClickOutside(event, navigatorDropdownRef, setNavigatorShowDropdown);
        };
        document.addEventListener('click', handleClickOutsideNavigatorDropdown, true);
        return () => {
            document.removeEventListener('click', handleClickOutsideNavigatorDropdown, true);
        };
    }, []);

    let handleScroll = (id, event) => {
      event.preventDefault(); // Verhindere die Standardaktion des Links
      let element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth' });
      setNavigatorShowDropdown(false); // Schließe das Dropdown-Menü nach dem Klicken auf einen Link
    };

    return (
        <div>
            <div id="topper-section">
                <div className="topper-left">
                    <img src={favicon} alt="Left Image" />
                </div>
                <div className="topper-right">
                    <button onClick={handleLanguageToggleDropdown}>
                        {/* Bild und Text des Buttons basierend auf der ausgewählten Sprache */}
                        <img src={languages.find(lang => lang.code === selectedLanguage).flag} alt="flag" />
                        <span>{languages.find(lang => lang.code === selectedLanguage).name}</span>
                    </button>
                    {showLanguageDropdown && (
                        <div className="topper-dropdown" ref={languageDropdownRef}>
                            {languages.map((language, index) => (
                                <button key={index} onClick={() => changeLanguage(language.code)}>
                                    <img src={language.flag} alt={language.name} />
                                    <span>{language.name}</span>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div id="overall-section">
                <div className="container overall-container">

                    {/* TODO: Delete with release */}
                    <div class="banner">
                        <div class="marquee">
                            <div class="marquee-inner">
                                <span>Get Ready for the Future of Crypto! Launching soon!!</span> {/* TODO: Change with release Date */}
                            </div>
                        </div>
                    </div>

                    <h1>{name} - {symbol}</h1>
                    <div className="overall-box">
                        <Timer nextRaise={nextRaise} />
                        <h2>{t('overall_headline')}</h2>
                        <div id="overall-raise-bar">
                            <div id="overall-fill-bar"></div>
                            <div className="overall-raise-text">
                                {/* TODO: 0 -> {moneyEarned.toLocaleString()} with release*/}
                                RAISED: 0 $ / {maxAmountToRaise.toLocaleString()} $
                            </div>
                        </div>
                        <div>
                            {/* TODO: 0,26 -> {tokenPrice.toLocaleString()} with release*/}
                            <p>1 {symbol} = 0,26$</p>
                            <p>{t('purchased_token')} {symbol} = ${icoBalance.toLocaleString()}</p>
                            <p>{t('staked_tokens')} {symbol} = ${stakeOf.toLocaleString()}</p>
                        </div>
                        <div id="overall-rectangle-container">
                            <div id="overall-rectangle">
                                <p>{t('payment')}: $ETH</p>
                                <input className="input-ETH" type="number" min="0" placeholder="0" value={paymentValue} onChange={handlePaymentInput}/>
                                <w3m-button balance='hide'/>
                            </div>
                            <div id="overall-rectangle">
                                <p>{t('receive')}: {symbol}</p>
                                <input className="input-PSHIB" type="number" min="0" placeholder="0" value={receiveValue} onChange={handleReceiveInput} />
                                {/* TODO: disabled=false with release */}
                                <button onClick={() => handleBuyTokens()} disabled={true} className="overall-buy-button">BUY</button>
                            </div>
                        </div>

                        <div>
                            {purchaseStatus && (
                            <p style={{ color:
                                purchaseStatus === 'success' ? 'green' :
                                purchaseStatus === 'connection_error' ? 'orange' : 'red' }}>
                                {purchaseStatus === 'success' ? t('purchase_success_message') :
                                purchaseStatus === 'connection_error' ? t('connection_error_message') :
                                t('error_message')}
                            </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div id="stake-section">
                <h1>STAKE</h1>
                <div className="stake-container">
                    <div className="stake-box">
                        <h2>{t('stake_headline_one')}</h2>
                        <p>Your staked {symbol} = ${stakeOf.toLocaleString()} / ${icoBalance.toLocaleString()}</p>
                        <div className="stake-input">
                            <input type="number" className="input-PSHIB" min="0" placeholder="0" value={stakeInput} onChange={handleStakeInput}/>
                            <button onClick={() => handleStakeButton()} className="stake-button">STAKE</button>
                        </div>
                        <div>
                            {stakeStatus && (
                            <p style={{ color: stakeStatus === 'success' ? 'green' : 'red' }}>
                                {stakeStatus === 'success' ? t('stake_success_message') : t('error_message')}
                            </p>
                            )}
                        </div>
                        <img src={stake1} alt="Passive Income Generation through Rewards"/>
                    </div>
                    <div className="stake-box">
                        <h2>{t('stake_headline_two')}</h2>
                        <p>{t('stake_info_one')}</p>
                        <p>{t('stake_info_two')}</p>
                        <img src={stake2} alt="Passive Income Generation through Rewards"/>
                    </div>
                </div>
            </div>

            <div id="h2b-section">
                <div className="container h2b-container">
                    <h1>HOW TO BUY</h1>
                    <div className="h2b-box">
                        <h1>{t('step_one')}</h1>
                        <h2>{t('step_one_headline')}</h2>
                        <p>{t('step_one_text', { symbol: symbol })}</p>
                    </div>
                    <div className="h2b-box">
                        <h1>{t('step_two')}</h1>
                        <h2>{t('step_two_headline')}</h2>
                        <p>{t('step_two_text', { symbol: symbol })}</p>
                    </div>
                    <div className="h2b-box">
                        <h1>{t('step_three')}</h1>
                        <h2>{t('step_three_headline')}</h2>
                        <p>{t('step_three_text', { symbol: symbol })}</p>
                   </div>
                   <div className="h2b-box">
                        <h1>{t('step_four')}</h1>
                        <h2>{t('step_four_headline')}</h2>
                        <p>{t('step_four_text', { symbol: symbol })}</p>
                    </div>
                </div>
            </div>

            <div id="airdrop-section">
                <h1>AIRDROP</h1>
                <div className="airdrop-container">
                    <div className="airdrop-box">
                        <h2>{t('airdrop_one_headline')}</h2>
                        <ul>
                            <li>{t('airdrop_one_text_one')}</li>
                            <p>{t('airdrop_one_text_two', { symbol: symbol })}</p>
                            <li>{t('airdrop_one_text_three')}</li>
                            <p>{t('airdrop_one_text_five')}</p>
                            <p>{t('airdrop_one_text_six')}</p>
                        </ul>
                        <img src={airdrop2} alt="WHEN DOES AN AIRDROP ARRIVE"/>
                    </div>
                    <div className="airdrop-box">
                        <h2>{t('airdrop_two_headline')}</h2>
                        <ul>
                            <li>{t('airdrop_two_text_one')}</li>
                            <li>{t('airdrop_two_text_two')}</li>
                            <li>{t('airdrop_two_text_three')}</li>
                            <li>{t('airdrop_two_text_four')}</li>
                            <li>{t('airdrop_two_text_five')}</li>
                        </ul>
                        <img src={airdrop1} alt="WHAT'S AN AIRDROP"/>
                    </div>
                </div>
            </div>

            <div id="about-section">
                <h1>{t('about_headline')}</h1>
                <div className="about-box">
                    <p>{t('about_text_one')}</p>
                    <img src={about1} alt="about1"/>
                </div>
                <div className="about-box">
                    <img src={about2} alt="about2"/>
                    <p>{t('about_text_two')}</p>
                </div>
                <div className="about-box">
                    <p>{t('about_text_three')}</p>
                    <img src={about3} alt="about3"/>
                </div>
                <div className="about-box">
                    <img src={about4} alt="about4"/>
                    <p>{t('about_text_four')}</p>
                </div>
            </div>

            <div id="tokenomics-section">
                <h1>TOKENOMICS</h1>
                <div className="tokenomics-container">
                    <img src={tokenomics} alt="tokenomics"/>
                    <div className="tokenomics-box">
                        <h2>{t('tokenomics_headline')}</h2>
                        <ul>
                            <li>{t('tokenomics_ico')}</li>
                            <li>{t('tokenomics_stake')}</li>
                            <li>{t('tokenomics_marketing')}</li>
                            <li>{t('tokenomics_dex')}</li>
                        </ul>
                        {/* TODO: TBD ->  {address} with release*/}
                        <p>{symbol} Token Address: TBD</p>
                    </div>
                </div>
            </div>

            <div id="community-section">
                <div className="container community-container">
                    <h1>{t('community_headline')}</h1>
                    <p>{t('community_text')}</p>
                    <div className="buttons">
                        <a href="https://twitter.com/PuppyShiba38085" target="_blank" rel="noopener noreferrer">
                            <button className="button community-x-button"/>
                        </a>
                     </div>
                </div>
            </div>

            <div className="footer">
                <button className="button footer-button" onClick={() => openModal('Cookies', cookieText)}>Cookies</button>
                <button className="button footer-button" onClick={() => openModal('Privacy Policy', privacyPolicyText)}>{t('privacy_policy')}</button>
                <p className="footer-text">® 2024 Puppy Shiba Inu | Rights Reserved | contact@puppyshibainu.com</p>

                <Modal isOpen={modalIsOpen} onRequestClose={closeModal} ariaHideApp={false} shouldCloseOnOverlayClick={false} className="ReactModal__Content" overlayClassName="ReactModal__Overlay">
                    <div>
                        <h2 className="ModalTitle">{modalTitle}</h2>
                        {modalContent.map((paragraph, index) => (
                            <p key={index} className="ModalContent">{paragraph}</p>
                        ))}
                        <button className="ModalCloseButton" onClick={(e) => { e.preventDefault(); closeModal(); }}>Close</button>
                    </div>
                </Modal>
            </div>

            <div className="navigator" ref={navigatorDropdownRef}>
                <img src={navigator} alt="Navigator" className="navigator-toggle" onClick={handleNavigatorToggleDropdown} />
                {showNavigatorDropdown && (
                <div className="navigator-dropdown">
                <a href="#" onClick={(e) => handleScroll('topper-section', e)}>{t('navigator_home')}</a>
                <a href="#" onClick={(e) => handleScroll('stake-section', e)}>STAKE</a>
                <a href="#" onClick={(e) => handleScroll('h2b-section', e)}>{t('navigator_h2b')}</a>
                <a href="#" onClick={(e) => handleScroll('airdrop-section', e)}>AIRDROP</a>
                <a href="#" onClick={(e) => handleScroll('about-section', e)}>ABOUT</a>
                <a href="#" onClick={(e) => handleScroll('tokenomics-section', e)}>TOKENOMICS</a>
                <a href="#" onClick={(e) => handleScroll('community-section', e)}>{t('navigator_community')}</a>
                </div>
              )}
            </div>
        </div>
    );
}

export default App;

