// Timer.js
import React, { useState, useEffect } from 'react';
import './Timer.css';

const Timer = ({ nextRaise }) => {
    const [timeRemaining, setTimeRemaining] = useState(null);

    useEffect(() => {
        const calculateTimeRemaining = () => {
            const now = new Date();

            // TODO: Reactivate when release date is set and delete with release
            // const releaseDate  = new Date('2024-09-17T17:00:00Z'); // 19:00 CEST ist 17:00 UTC
            // const nextRaise = Math.floor(releaseDate.getTime() / 1000);

            const timeDiff = (nextRaise * 1000) - now;

            if (timeDiff <= 0) {
                return { days: 0, hours: 0, minutes: 0, seconds: 0 };
            }

            let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            let hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            return { days, hours, minutes, seconds };
        };

        if (nextRaise) {
            const intervalId = setInterval(() => {
                setTimeRemaining(calculateTimeRemaining());
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [nextRaise]);

    if (!timeRemaining) {
        return <div>Loading...</div>;
    }

    return (
        <div className="timer-container">
            <div className="timer-item">
                <span className="timer-value">{timeRemaining.days}</span>
                <span className="timer-label">Days</span>
            </div>
            <div className="timer-item">
                <span className="timer-value">{timeRemaining.hours}</span>
                <span className="timer-label">Hours</span>
            </div>
            <div className="timer-item">
                <span className="timer-value">{timeRemaining.minutes}</span>
                <span className="timer-label">Minutes</span>
            </div>
            <div className="timer-item">
                <span className="timer-value">{timeRemaining.seconds}</span>
                <span className="timer-label">Seconds</span>
            </div>
        </div>
    );
};

export default Timer;
